<template>
    <div class="form-container">
        <div class="header">
            <p>Solicitar {{ formType }}</p>
            <h2>Formulario de contacto</h2>
        </div>

        <NForm :model="formValue" :rules="rules" ref="contactForm">
            <div class="flex-container">
                <NFormItem label="Nombre" path="name" required>
                    <NInput v-model:value="formValue.name" type="text" placeholder="Nombre" size="large" />
                </NFormItem>

                <NFormItem label="Tipo de negocio" path="business" required>
                    <NSelect @update:value="handleUpdateBusiness" :options="optionLabels"
                        placeholder="Seleccionar negocio" size="large" />
                </NFormItem>

                <NFormItem label="Teléfono" path="phone" required>
                    <NInput v-model:value="formValue.phone" type="tel" placeholder="Teléfono" size="large" />
                </NFormItem>

                <NFormItem label="Correo electrónico" path="email" required>
                    <NInput v-model:value="formValue.email" type="email" placeholder="Correo electrónico"
                        size="large" />
                </NFormItem>
            </div>

            <NFormItem label="Dirección" path="address" required>
                <NInput v-model:value="formValue.address" :placeholder="address" size="large" disabled />
            </NFormItem>

            <NFormItem label="Comentarios" path="comments">
                <NInput v-model:value="formValue.comments" type="textarea" placeholder="Escriba aquí sus comentarios"
                    size="large" />
            </NFormItem>

            <NFormItem style="display: flex; justify-content: center;">
                <vue-recaptcha v-show="true" sitekey="6LfhA84pAAAAAJhdEQe_H6wgP9Dd5x0V54FWic26" size="normal"
                    theme="light" hl="es" :loading-timeout="30000" @verify="onCaptchaVerified"
                    @expire="recaptchaExpired" @error="recaptchaError" ref="vueRecaptcha" class="captcha">
                </vue-recaptcha>
            </NFormItem>

            <NFormItem style="display: flex; justify-content: center;">
                <Button v-if="recaptchaToken" @click="submit" buttonText="Enviar" :disabled="!isValid" />
            </NFormItem>

        </NForm>
    </div>
</template>


<script>
import { mapState } from "vuex"
import { NForm, NFormItem, NInput, NSelect, useMessage } from "naive-ui"
import Button from "./Button.vue"
import vueRecaptcha from 'vue3-recaptcha2'
import backendService from "../services/backend.service"

export default {
    name: "Form",
    components: {
        useMessage,
        NForm,
        NFormItem,
        NInput,
        NSelect,
        Button,
        vueRecaptcha
    },
    computed: {
        ...mapState([ 'address', 'formType' ])
    },
    data() {
        return {
            selectedOption: null,
            optionLabels: [
                { label: "Hostelería", value: "Hostelería" },
                { label: "Ocio", value: "Ocio" },
                { label: "Consumo", value: "Consumo" },
                { label: "Trabajo", value: "Trabajo" },
                { label: "Educación", value: "Educación" }
            ],
            formValue: {
                name: '',
                business: '',
                phone: '',
                email: '',
                address: '',
                comments: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Este campo es obligatorio.',
                        trigger: [ 'blur', 'input' ]
                    }
                ],
                business: [
                    {
                        required: true,
                        message: 'Este campo es obligatorio.',
                        trigger: [ 'blur', 'input' ]
                    }
                ],
                phone: [
                    {
                        required: true,
                        validator(rule, value) {
                            if (!value) {
                                return new Error("Este campo es obligatorio.")
                            }
                            if (!/^\d{9}$/.test(value)) {
                                return new Error("Introduce un número de teléfono válido.")
                            }
                            return true;
                        },
                        trigger: [ "blur", "input" ]
                    }
                ],
                email: [
                    {
                        required: true,
                        validator(rule, value) {
                            if (!value) {
                                return new Error("Este campo es obligatorio.")
                            }
                            if (!/\S+@\S+\.\S+/.test(value)) {
                                return new Error("Introduce un correo electrónico válido.")
                            }
                            return true;
                        },
                        trigger: [ "blur", "input" ]
                    }
                ]
            },
            isValid: false,
            message: useMessage(),
            recaptchaToken: null
        }
    },
    methods: {
        handleUpdateBusiness(value) {
            this.formValue.business = value
        },

        submit() {
            this.$refs.contactForm.validate(async (errors) => {
                if (!errors) {
                    const formData = this.formatFormData()
                    backendService
                        .sendEmail(formData)
                        .then(response => console.log(response))
                        .catch(err => console.log(err))

                    this.message.success("¡Estupendo! La información se ha enviado correctamente", { duration: 3000 })
                } else {
                    this.message.error('Formulario inválido.', { duration: 3000 })
                }
            })
        },
        formatFormData() {
            return {
                name: this.formValue.name,
                business: this.formValue.business,
                phone: this.formValue.phone,
                email: this.formValue.email,
                address: this.formValue.address,
                comments: this.formValue.comments,
                service_requested: this.formType
            }
        },

        validateForm() {
            const requiredFields = [ 'name', 'business', 'phone', 'email', 'address' ];
            const allFieldsFilled = requiredFields.every(field => this.formValue[ field ] !== '');

            if (allFieldsFilled) {
                this.$refs.contactForm.validate().then(() => {
                    this.isValid = true;
                }).catch(() => {
                    this.isValid = false;
                });
            } else {
                this.isValid = false;
            }
        },

        onCaptchaVerified(token) {
            this.recaptchaToken = token
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset()
        },
        recaptchaError(reason) {
            console.log(`Failed to validate reCAPTCHA with error: ${reason}`)
        }
    },
    watch: {
        formValue: {
            handler() {
                this.validateForm()
            },
            deep: true
        }
    },
    mounted() {
        this.formValue.address = this.address
    }
}
</script>


<style scoped>
.form-container {
    padding: 48px 26px;
    border-radius: 4px;
    background-color: white;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: start;
}

.header p,
h2 {
    margin: 0;
}

h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.fa-times {
    position: absolute;
    top: 23px;
    right: 23px;
    cursor: pointer;
    font-size: 20px;
    color: var(--base-color-900, #161616);
}

.text-area {
    align-content: start;
}

.captcha {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

@media (min-width: 834px) {
    .form-container {
        width: 659px;
        max-width: unset;
    }

    .flex-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
}

@media (min-width: 1280px) {
    .form-container {
        width: 861px;
    }
}
</style>